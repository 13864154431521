// sign user in annonymously to allow for uploads.

import React from "react"
import firebase from "gatsby-plugin-firebase"

function Login() {
  React.useEffect(() => {
    firebase.auth().signInAnonymously()
  }, [])

  return <></>
}

export default Login
