import React, { Component } from "react"
import { LogoFooter } from "../images"

class Footer extends Component {
  render() {
    return (
      <footer className="site-footer">
        <p className="body--xs sans">
          See our{" "}
          <a href="/privacy-policy" target="_blank" rel="noreferrer">
            privacy policy
          </a>{" "}
          for information on how we use your data. Campaign&nbsp;by&nbsp;
          <a href="https://studiomade.co/" target="_blank" rel="noreferrer">
            Studiomade
          </a>
        </p>
        <p className="body--xs sans">
          Orthopaedic Research UK is a UK Registered Charity No. 1111657 and a
          Company registered in England and Limited by Guarantee No. 5585452.
        </p>
        <p className="body--xs sans">
          Registered Office: Rosemount House, Rosemount Avenue, West Byfleet,
          Surrey KT14 6LB
        </p>
        <img src={LogoFooter} alt="ORUK" />
      </footer>
    )
  }
}
export default Footer
