import React, { Component } from "react"
import { Checkbox, Radio, Loader } from "semantic-ui-react"
import { loadReCaptcha } from "react-recaptcha-google"
import { ReCaptcha } from "react-recaptcha-google"
import firebase from "gatsby-plugin-firebase"
import { Redirect } from "@reach/router"
import scrollTo from "gatsby-plugin-smoothscroll"
const mandrill = require("mandrill-api/mandrill")
const mandrill_client = new mandrill.Mandrill(
  process.env.GATSBY_MANDRILL_API_KEY
)

const sendEmail = async userInfo => {
  const message = {
    subject: "Thanks for sharing your story",
    from_email: "ownpain@oruk.org",
    from_name: "ORUK - Own Pain",
    to: [
      {
        email: userInfo.email,
        type: "to",
      },
    ],
    merge_language: "handlebars",
    headers: {
      "Reply-To": "ownpain@oruk.org",
    },
    html:
      userInfo.name +
      "<p>Thank you so much for sharing your story of living with bone, joint or muscle pain.</p>" +
      "<p>We hope that by sharing real life experiences like yours, we can raise awareness of chronic pain and show people they're not alone.</p>" +
      "<p>Your story may be used on our website, social media, emails and in other ways as part of Orthopaedic Research UK's Own Pain campaign. </p>" +
      "<p>By offering support, resources and a sense of community, the campaign aims to help people find ways to reduce their pain and live more active, healthier lives.</p>" +
      "<p>If you have any questions, or would like to talk about how your story will be used, you can contact us on <email> or call <phone number>.</p>" +
      "<p>Thanks again and best wishes,</p>" +
      "<p></p>" +
      "<p>Orthopaedic Research UK</p>",
  }

  const confirmationMessage = {
    subject: "New submission",
    from_email: "ownpain@oruk.org",
    from_name: userInfo.email,
    to: [
      {
        email: "ownpain@oruk.org",
        type: "to",
      },
    ],
    merge_language: "handlebars",
    headers: {
      "Reply-To": "ownpain@oruk.org",
    },
    html:
      "<p>Name: " +
      userInfo.name +
      "</p>" +
      "<p>Email: " +
      userInfo.email +
      "</p>" +
      "<p>Story: " +
      userInfo.story +
      "</p>" +
      "<p>Upload: " +
      userInfo.video +
      "</p>" +
      "<p>Agree to be contacted: " +
      userInfo.gdpr +
      "</p>",
  }

  return new Promise((resolve, reject) => {
    mandrill_client.messages.send({ message: message }, function (result) {
      // now send confirmation email
      mandrill_client.messages.send({ message: confirmationMessage }, function (
        result
      ) {
        console.log(result)
        window.location.replace(`/thankyou`)
      })
    })
  })
}

// eslint-disable-next-line
const validEmailRegex = RegExp(
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i //eslint-disable-line
)

const validateForm = errors => {
  let valid = true
  Object.values(errors).forEach(
    // if we have an error string set valid to false
    val => val.length > 0 && (valid = false)
  )
  return valid
}

class UploadForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      name: "",
      email: "",
      story: "",
      gdpr: "yes",
      upload: "",
      video: "",
      progress: 0,
      errors: {
        name: "",
        email: "",
      },
      count: 0,
    }

    this.handleChange = this.handleChange.bind(this)
    this.onLoadRecaptcha = this.onLoadRecaptcha.bind(this)
    this.verifyCallback = this.verifyCallback.bind(this)
  }

  handleChange = (e, data) => {
    let name = e.target.name
    let value = e.target.value
    let errors = this.state.errors

    if (name === "story") {
      this.setState({
        count: value.length,
      })
    }

    if (data) {
      name = data.name
      value = data.checked

      if (name === "gdpr") {
        value = data.value
      }
    }

    switch (name) {
      case "name":
        errors.name = value.length < 1 ? "→ Error: name is required" : ""
        break
      case "email":
        errors.email = validEmailRegex.test(value)
          ? ""
          : "→ Error: valid email address required"
        break
      default:
        break
    }

    if (e.target.name === "file") {
      if (e.target.files[0]) {
        const file = e.target.files[0]
        const maxSize = 1000000000
        const size = file.size

        if (size > maxSize) {
          this.setState({
            upload_status: "error",
          })
        } else {
          this.setState({
            upload: file,
            upload_name: file.name,
            upload_status: "success",
          })
        }
      }
    }

    this.setState({
      errors: errors,
      [name]: value,
    })
  }

  handleSubmit = e => {
    e.preventDefault()

    const { name, email, upload } = this.state
    let errors = this.state.errors

    if (!name) {
      errors.name = "→ Error: name is required"
    }

    if (!email) {
      errors.email = "→ Error: valid email address required"
    }

    this.setState({
      errors: errors,
      sending: true,
    })

    if (validateForm(errors)) {
      if (upload.name) {
        firebase
          .storage()
          .ref("uploads/" + upload.name)
          .put(upload)
          .on(
            "state_changed",
            snapshot => {
              // progress function ...
              const progress = Math.round(
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100
              )
              this.setState({ progress })
            },
            error => {
              console.log(error)
              // Error function ...
            },
            () => {
              // complete function ...
              firebase
                .storage()
                .ref("uploads")
                .child(upload.name)
                .getDownloadURL()
                .then(url => {
                  this.setState({
                    video: url,
                    video_name: upload.name,
                    upload_status: "success",
                  })
                  this.captcha.execute()
                })
            }
          )
      } else {
        this.captcha.execute()
      }
    } else {
      scrollTo("#form_container")
      this.setState({
        sending: false,
      })
    }
  }

  componentDidMount() {
    loadReCaptcha()
    if (this.captcha) {
      this.captcha.reset()
    }
  }

  onLoadRecaptcha() {
    if (this.captcha) {
      this.captcha.reset()
    }
  }

  verifyCallback(recaptchaToken) {
    if (recaptchaToken) {
      // this.sendEmail()
      firebase
        .firestore()
        .collection("uploads")
        .add({
          name: this.state.name,
          email: this.state.email,
          video: this.state.video,
          story: this.state.story,
          gdpr: this.state.gdpr,
          createdAt: new Date(),
        })
        .then(() => {
          sendEmail(this.state)
        })
        .catch(err => {
          console.log(err)
        })
    }
  }

  render() {
    if (this.state.complete) return <Redirect to="thankyou" noThrow />

    return (
      <div className="form-container" id="form_container">
        <form className="upload_form" onSubmit={this.handleSubmit}>
          <div
            className={
              this.state.errors.name ? "error form-input" : "form-input"
            }
          >
            <span className="label" htmlFor="name">
              Name
            </span>
            <input
              id="name"
              name="name"
              onChange={this.handleChange}
              type="text"
            />
            {this.state.errors.name && (
              <p className="form_error">{this.state.errors.name}</p>
            )}
          </div>
          <div
            className={
              this.state.errors.email ? "error form-input" : "form-input"
            }
          >
            <span className="label" htmlFor="email">
              Email address
            </span>
            <input
              id="email"
              name="email"
              onChange={this.handleChange}
              type="email"
            />
            {this.state.errors.email && (
              <p className="form_error">{this.state.errors.email}</p>
            )}
          </div>
          <div className={this.state.upload_status + " upload"}>
            <div className="upload_note">
              <p className="body--xs sans">
                Note: If you’re on a desktop computer, you’ll just need to send
                yourself the video file from your phone or access this site from
                that device.
              </p>
            </div>
            <h4 className="heading--s bold uppercase sans">file upload</h4>
            <p className="body--m sans">
              Attach your video here. Or if you’re writing your story, please
              upload a photo.
            </p>
            <div className="file-upload">
              <span className="body--m sans">
                {this.state.upload_name
                  ? this.state.upload_name
                  : "Choose file"}
                <span className="heart-icon">❤</span>
              </span>
              <input
                onChange={this.handleChange}
                type="file"
                name="file"
                id="file-1"
                className="inputfile inputfile-1"
                data-multiple-caption="{count} files selected"
                multiple={false}
                accept="video/mp4, video.mov, image/png, image/jpg"
              />
            </div>
            <p className="body--s sans">
              Accepted file types: .mov, .mp4, .jpg, .png (maximum size: 100mb)
            </p>
            {this.state.upload_status === "error" && (
              <p className="heading--xs sans bold upload_error">
                → Error: File size is too large
              </p>
            )}
          </div>
          <div className="form-input">
            <div className="textarea_label">
              <span className="label" htmlFor="story">
                Story
              </span>
              <span className="word_count">{this.state.count}/1000</span>
              <span className="label_span">Optional</span>
            </div>
            <textarea
              id="story"
              name="story"
              onChange={this.handleChange}
            ></textarea>
          </div>
          <div className="form-input form-radio">
            <h4 className="heading--xs bold sans">
              Want to hear more about this campaign?
            </h4>
            <p className="body--s sans">
              Choose ‘Yes’ to get emails from Orthopaedic Research UK about the
              Own Pain campaign, as well as other news and fundraising updates
              by email. You can unsubscribe at any time.
            </p>

            <Radio
              label="Yes"
              name="gdpr"
              value="yes"
              checked={this.state.gdpr === "yes"}
              onChange={this.handleChange}
            />

            <Radio
              label="No"
              name="gdpr"
              value="no"
              checked={this.state.gdpr === "no"}
              onChange={this.handleChange}
            />
          </div>
          {this.state.gdpr === "no" && (
            <div className="radio_alert">
              <p className="body--s sans">
                <strong>Are you sure?</strong> If you choose ‘No’, we won’t be
                able to email you when the Own Pain website is live. Click ‘Yes’
                to get email updates.{" "}
              </p>
            </div>
          )}
          <div className="form-input form-checkbox">
            <Checkbox
              name="terms"
              onChange={this.handleChange}
              label={{
                children: (
                  <span>
                    I am happy for my story to be used as described in the{" "}
                    <a href="/terms-of-use" target="_blank">
                      Terms of Use
                    </a>
                  </span>
                ),
              }}
            />
          </div>
          <ReCaptcha
            ref={el => {
              this.captcha = el
            }}
            size="invisible"
            data-theme="dark"
            render="explicit"
            sitekey="6Lf7b6UZAAAAAJ_7xMhdhY9ZLMANLXKcejMh6XBo"
            onloadCallback={this.onLoadRecaptcha}
            verifyCallback={this.verifyCallback}
          />
          <div className="btn_container center">
            <button
              className="btn green right"
              disabled={!this.state.terms || this.state.sending}
            >
              <span>{this.state.sending ? "Sending" : "Send"}</span>
            </button>
          </div>
          {this.state.sending && (
            <Loader active inline="centered" size="small" />
          )}
        </form>
        <p className="form_message sans body--s">
          Have a question? Need help? Send us a note:{" "}
          <a href="mailto:ownpain@oruk.org">ownpain@oruk.org</a>
        </p>
      </div>
    )
  }
}

export default UploadForm
