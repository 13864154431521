import React from "react"
import SEO from "./seo"

const Layout = props => {
  return (
    <>
      <SEO title={props.seo.title} description={props.seo.description} />
      <main>{props.children}</main>
    </>
  )
}

export default Layout
